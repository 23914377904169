import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/index.css'
import { RouteConfig } from "vue-router"
import { StringUtils } from './utils';

Vue.config.productionTip = false

Vue.use(ElementUI);

router.beforeEach(async (to, from, next) => {
  // console.log("to.path===>", to.path);
  const loginPath = '/facegate/login'
  if (to.path === loginPath) {
    next()
    return
  }

  if (!sessionStorage.getItem("isLogin")) {
    next(loginPath);
  } else {
    if (store.state.needReload === true) { // 页面被刷新， 重新加载Vuex数据

      const devSn = sessionStorage.getItem("devSn") as string
      store.commit("devSn", devSn)

      const deviceInfo = await store.dispatch("getDeviceInfo", devSn) as RouteConfig
      store.commit("deviceInfo", deviceInfo)
      store.commit("needReload", false)

      let userRouter = await store.dispatch("getUserRouter", devSn) as RouteConfig
      router.addRoute(userRouter)
      store.commit("userRouter", userRouter)
      // 添加新路由后要用重定向
      next(to.path);
    } else {
      next()
    }
    // console.log("devSn==", sessionStorage.getItem("devSn"))
    // console.log("userRouter==", store.state.userRouter)
  }
})


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

// const aa = StringUtils.b64Encode("你好")
// const bb = StringUtils.b64Decode(aa)
// console.log(aa, "   ", bb);

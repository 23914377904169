import { State, DeviceInfo } from "./state";
import { RouteConfig } from "vue-router"

export default {

    needReload(state: State, payload: boolean) {
        // console.log("commit needReload ==>" + payload);
        state.needReload = payload
    },

    devSn(state: State, payload: string) {
        // console.log("commit devSn ==>" + payload);
        state.devSn = payload
    },

    deviceInfo(state: State, payload: DeviceInfo) {
        // console.log("commit deviceInfo ==>" + payload);
        state.deviceInfo = payload
    },

    userRouter(state: State, payload: RouteConfig) {
        // console.log("commit userRouter ==>", payload);
        state.userRouter = payload
    },

    currentRouteName(state: State, payload: string) {
        // console.log("commit userRouter ==>" + payload);
        state.currentRouteName = payload
    },
}
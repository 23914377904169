import { RouteConfig } from "vue-router"

// State不能用可选类型，不然Vuex无法跟踪

export interface DeviceInfo {
    token: string,
    aesKey: string,
    devName: string,
    productKey: string,
    secret: string,
    authorization: string,
}

export interface State {
    needReload: boolean
    devSn: string
    token: string
    userRouter: RouteConfig,
    currentRouteName: string,
    deviceInfo: DeviceInfo
}

const state: State = {
    needReload: true, // 默认为true。当页面刷新vuex内容被重置时，根据这个值判断是否需要重新请求数据
    devSn: "",
    token: "",
    userRouter: {
        path: ""
    },
    currentRouteName: "",
    deviceInfo: {
        token: "",
        aesKey: "",
        devName: "",
        productKey: "",
        secret: "",
        authorization: "",
    }
}

export default state
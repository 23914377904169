import { get, post } from './request'
import { DeviceInfo } from '@/store/state';

interface LoginResponse {
    isok: boolean,
    code: number,
    msg: string,
    data: DeviceInfo
}

const FacegateLogin = post('/login');
const FacegateSelect = post('/select');
const FacegateCheck = post('/check');
const FacegatePublish = post('/publish');
const FacegateDelete = post('/delete');

export {
    FacegateLogin,
    FacegateSelect,
    FacegatePublish,
    FacegateDelete,
    FacegateCheck,
    LoginResponse,
}


import { ActionContext, ActionHandler, Store } from "vuex";
import { State } from './state'
import { RouteConfig } from 'vue-router'
import { FacegateLogin, LoginResponse } from "@/api";
import { AxiosResponse } from 'axios';

export default {
    async getDeviceInfo(context: ActionContext<State, State>, devSn: string) {
        const { data: deviceInfo } = await FacegateLogin({ devSn }) as AxiosResponse<LoginResponse>
        return deviceInfo
    },

    async getUserRouter(context: ActionContext<State, State>, devSn: string) {
        const userRouter: RouteConfig = {
            path: `/facegate/${devSn}`,
            component: () => import("@/views/Home.vue"),
            children: [
                {
                    path: `check`,
                    name: "人脸审核",
                    component: () => import("@/views/FaceCheck.vue"),
                },
                {
                    path: `manage`,
                    name: "人脸管理",
                    component: () => import("@/views/FaceManage.vue"),

                },
            ]
        };
        return userRouter
    }
}


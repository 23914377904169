import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '@/store'
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: "/facegate/login"
  },

  {
    path: '*',
    name: 'NotFound',
    component: () => import('@/views/NotFound.vue')
  },
  {
    path: '/facegate/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/facegate/upload',
    name: 'Upload',
    component: () => import('@/views/UploadTest.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

import axios from 'axios';

const request = axios.create({
    // baseURL: 'http://39.105.84.142:9090/facegate',
    baseURL: 'http://112.74.57.111:9090/facegate',
});

export function get(url: string) {
    return function (params: any) {
        return request
            .get(url, {params})
            .then(result => {
                return result.data;
            })
            .catch(e => {
                console.log(e);
            });
    }
}

export function post(url: string) {
    return function (data: any) {
        return request
            .post(url, data)
            .then(result => {
                return result.data
            })
            .catch(e => {
                console.log(e);
            });
    }
}

